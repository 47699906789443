import { toast } from 'react-toastify';
import { apiV1 } from '../lib/axios';
import { useRegister } from '../hooks/useRegister';
import moment from 'moment';

export const CreateKey = async (keyType, transationalPassword, navigate) => {
  let response = await apiV1.post('/pixkey/store', {
    pixKeyType: keyType,
    transational_password: transationalPassword,
  });
  if (response.status === 200) {
    toast.info('Aguarde, sua nova chave será criada em breve!');
    return { isCreated: true };
  } else {
    toast.error('Erro ao criar uma nova chave!');
    return false;
  }
};
export const SendConfirmationCode = async (keyType, confirmationCode) => {
  const endpoint = "/pixkey/confirmationcode";

  try {
    await apiV1.post(endpoint, {
      pixKeyType: Number(keyType),
      confirmationCode: confirmationCode,
    });
  } catch (error) {
    throw error;
  }

}

export const ResendConfirmationCodeToUser = async (keyType) => {
  const endpoint = "/pixkey/resendconfirmationcodetouser";

  try {
    await apiV1.post(endpoint, {
      pixKeyType: keyType,
    });
  } catch (error) {
    throw error;
  }
}


export const getAll = async () => {
  try {
    let response = await apiV1.post('/pixkey/getKeys/', {
    });
    if (response.status === 200) {
      return response.data.data;
    } else {
      toast.error('Erro ao carregar as chaves!');
      return '';
    }
  } catch (err) {
    toast.error(err.response.data.message);
    return '';
  }
};

export const deleteKey = async (id, navigate) => {
  let deleteResponse = await apiV1.delete(`/pixkey/delete/${id}`);
  if (deleteResponse.status === 200) {
    toast.success('Chave Deletada com Sucesso!');
    return true;
  } else {
    toast.error('Erro ao deletar a chave!');
    return false;
  }
};

export const getPixInfo = async (key, type) => {
  let payload = {
    key,
    keyType: type,
  };

  let infos = await apiV1.post('/pix/fitbank/getinfos', payload);
  return infos;
};

export const sendPixFitBank = async (data) => {
  let response = await apiV1
    .post('/pix/fitbank/transfer', data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      toast.error(`${err.response.data.message} PX005`);
      return err;
    });

  return response.data;
};

export const sendMfaPix = async () => {
  await apiV1.post("/pix/fitbank/mfa")
      .catch((err) => {
        toast.error("Erro ao enviar o MFA",err);
        return;
      });
    return
  }

  export const sendMfaAuthenticate = async (otp_) => {
    const otp = {otp:otp_}
    const response = await apiV1.post("/pix/fitbank/mfa/authenticate",otp)
        .catch((err) => {
          toast.error("Erro ao autenticar o MFA",err);
          return;
        });
    
      return response;
    }

export const listPix = async () => {
  let infos = await apiV1.post('/pix/fitbank/list');
  return infos.data;
};

export const listContacts = async () => {
  try{
    let infos = await apiV1.get('/pixfavorite/list');
    return infos.data;
  } catch(err){
    return {
      message: err.message
    }
  }
}

export const createQrcodePixFitBank = async (pixKey, value) => {
  let payload = {
    pixKey,
    value,
  };
  let infos = await apiV1.post('/pixqrcode/fitbank/create', payload);
  return infos.data;
};

export const getQrcodeInfoFitBank = async (hash) => {
  let payload = {
    hash,
  };
  let infos = await apiV1.post('/pixqrcode/fitbank/getinfo', payload);
  return infos.data;
};

export const getLimitsFitBank = async () => {
  let payload = {
    taxNumber: localStorage.getItem('taxNumber'),
  };
  let infos = await apiV1.get('/pix/fitbank/limits', payload);
  return infos.data;
};

export const setLimitsFitBank = async (values) => {
  let payload = {
    values,
  };
  let infos = await apiV1.post('/pix/fitbank/limits', payload);
  return infos.data;
};

export const requestPixLimit = async (
  type,
  value,
  registeredDocument,
  justification
) => {
  try {
    if (value === undefined || value === null) {
      throw new Error('O valor deve ser definido e não pode ser nulo.');
    }

    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
      throw new Error('O valor deve ser um número válido.');
    }

    if (!registeredDocument) {
      throw new Error('O documentData deve ser definido e não pode ser nulo.');
    }

    const {
      representativeId = null,
      documentFile,
      documentFormat,
      documentName,
      documentType,
      description,
    } = registeredDocument;

    const payload = {
      type,
      values: numericValue,
      document: {
        representativeId,
        documentFile,
        documentFormat,
        documentName,
        documentType,
        description,
      },
      justification,
      operationType: 40,
      subType: 1,
    };

    const response = await apiV1.post('/pix/limit/request', payload);
     console.log("payload ", payload);
    if (response.status !== 200) {
      throw new Error(`Erro HTTP! Status: ${response.status}`);
    }

    const { lastDailyRequest, lastOvernightRequest, lastTransactionRequest } =
      response.data.data.limits;
    return {
      success: response.data.ok,
      message: response.data.message,
      lastDailyRequest: lastDailyRequest || null,
      lastOvernightRequest: lastOvernightRequest || null,
      lastTransactionRequest: lastTransactionRequest || null,
    };
  } catch (error) {
    console.error('Erro ao verificar solicitação de aumento de limite:', error);
    const errorMessage =
      error.response?.data?.message ||
      'Erro ao verificar solicitação de aumento de limite';
    const currentTimestamp = new Date().toISOString();

    return {
      success: false,
      message: errorMessage,
      lastDailyRequest: currentTimestamp,
      lastOvernightRequest: currentTimestamp,
      lastTransactionRequest: currentTimestamp,
    };
  }
};


export const identificarTipoChavePix = (chave) => {
  const regexCNPJ = /^(\d{14}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/;
  const regexTelefone = /^(\+\d{2}(\d{2}|\d{3})\d{8,9}|\d{11,12})$/;
  const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (validaCpf(chave)) {
    return 1; // Chave PIX do tipo CPF
  } else if (regexCNPJ.test(chave)) {
    return 1; // Chave PIX do tipo CNPJ
  } else if (regexEmail.test(chave)) {
    return 2; // Chave PIX do tipo e-mail
  } else if (regexTelefone.test(chave)) {
    return 3; // Chave PIX do tipo telefone
  } else {
    return 4; // Chave PIX com formato desconhecido ou chave aleatória
  }
};

export const hideTaxNumber = (taxNumber) => {
  if (taxNumber) {
    let originalTaxNumber = taxNumber.toString();
    let formattedTaxNumber;
    if (originalTaxNumber.length === 14) {
      formattedTaxNumber = `** *** ${originalTaxNumber.slice(
        5,
        8
      )} /**** - ${originalTaxNumber.slice(-2)}`;
    } else {
      formattedTaxNumber = `*** *** ${originalTaxNumber.slice(
        6,
        9
      )} - ${originalTaxNumber.slice(-2)}`;
    }
    return formattedTaxNumber;
  }
};

export const validaCpf = (cpf) => {
  const cpfStr = cpf.replace(/[^\d]+/g, '');
  // Verifica se a variável cpf está em branco ou é igual a "undefined", exibindo uma msg de erro
  if (cpfStr === '' || cpfStr === undefined) {
    return false;
  }
  // Elimina CPFs invalidos conhecidos
  if (
    cpfStr.length !== 11 ||
    cpfStr === '00000000000' ||
    cpfStr === '11111111111' ||
    cpfStr === '22222222222' ||
    cpfStr === '33333333333' ||
    cpfStr === '44444444444' ||
    cpfStr === '55555555555' ||
    cpfStr === '66666666666' ||
    cpfStr === '77777777777' ||
    cpfStr === '88888888888' ||
    cpfStr === '99999999999'
  ) {
    return false;
  }

  // Valida 1o digito
  let soma = 0;
  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpfStr.charAt(i)) * (10 - i);
  }
  let resto = 11 - (soma % 11);
  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== parseInt(cpfStr.charAt(9))) {
    return false;
  }

  // Valida 2o digito
  soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpfStr.charAt(i)) * (11 - i);
  }
  resto = 11 - (soma % 11);
  if (resto === 10 || resto === 11) {
    resto = 0;
  }

  if (resto !== parseInt(cpfStr.charAt(10))) {
    return false;
  }
  return true;
};
