import ReactInputMask from "react-input-mask";
import styled from "styled-components";

export const EntryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow: auto;
  opacity: 0.9;
  margin: auto;

  &:first-child {
    height: 100%;
  }
  & h2 {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 1px;
  }
`;

export const EntryHeader = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  & a {
    color: var(--header-link-color);
    font-size: 12px;
    font-weight: 400;
  }
`

export const EntryFilter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: start;
  color: var(--text-primary);
  width: 100%;
  margin-top: 5%;

  & h2 {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  & label {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-right: 10px;
  }
  & section {
    display: flex-column;
    width: 100%;
    margin: 0;
  }
  &button {
   margin-top: -30px;
   background-color: black;
   border-radius: 50%;
   height: 30px;
    width: 30px;
  }

  & strong {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0.5px;
  }

`

export const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 3%;
  border: none;

& label {
  margin-bottom: 0.5rem;
}
&input {
  width:78%;
  padding: 0.5rem;
  border: 3px solid black;
  border-radius: 0.25rem;
  font-size: 1rem;
  padding-left: 30px;
}
&textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  font-size: 1rem;
}

& strong {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0.5px;
}

& p {
  font-weight: 550;
  font-size: 14px;
}

& input[readonly] {
    background-color: #e9ecef; /* Cor de fundo para parecer desabilitado */
    color: #6c757d; /* Cor do texto mais clara */
    border: 1px solid #ced4da; /* Mantém a borda, mas com cor mais clara */
    cursor: not-allowed; /* Cursor para indicar que o campo não é editável */
  }

`
export const FilterButton = styled.button`
  display: flex;
  width: 150px;
  height: 38px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;

  background-color: ${({theme}) => theme.colors['filter-button-background'] };
  color: ${({theme}) => theme.colors['filter-button-color'] };
  font-weight: 900;
  font-family: Poppins;
  border: ${({theme}) => theme.colors['filter-button-border'] };
`

export const EntryList = styled.div`
`

export const EntryRow = styled.button`
  display: flex;
  width: 150px;
  height: 38px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border-color: ${({theme}) => theme.colors['filter-button-border'] };

  background-color: grey;
  color: ${({theme}) => theme.colors['filter-button-color'] };
  border: ${({theme}) => theme.colors['filter-button-background'] };
`

export const LoaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: start;
  color: var(--text-primary);
  width: 100%;
  margin-bottom: 5%;

  & h2 {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  & label {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-right: 10px;
  }

  & section {
    display: flex-column;
    width: 100%;
    margin: 0;
  }

  & img {
    margin-left: 18%;
  }
`
export const EntryScroll  = styled.div`
  border-bottom: 1px solid grey;
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: start;
  color: var(--text-primary);
  width: 100%;
  margin-bottom: 2%;
  padding-bottom: 2%;

  & h2 {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  & label {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-right: 10px;
  }

  & section {
    display: flex-column;
    width: 100%;
    margin: 0;
  }

  & p {
    margin-bottom: 13px;
    margin-left: 10px;

  }

  & input {
    margin-bottom: 17px;
    margin-left: 280px;
   
  }

  & img {
    opacity: 0.5;
    filter: grayscale(10%);
  }
`
export const EntryMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: center;
  color: var(--text-primary);
  width: 100%;
  margin-bottom: 3%;
  margin-top: -5%;

  & button {
    width: 410px;
    border-radius: 13px;
    height: 52px;

    background-color: ${({theme}) => theme.colors['filter-button-background'] };
    color: ${({theme}) => theme.colors['filter-button-color'] };
    font-weight: 900;
    font-family: Poppins;
    border: ${({theme}) => theme.colors['filter-button-border'] };
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 5px;
  }
`

export const SelectClientButton = styled.button`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  background-color: ${({theme}) => theme.colors['filter-button-background'] };
  color: ${({theme}) => theme.colors['filter-button-color'] };
  width: 100%;
  border-radius: 3px;
  font-size: 14px;
  padding: 8px 8px;
`

export const EntryMonth = styled.input`
  height: 41px;
  width: 100%;
  align-items: center;
  border-radius: 4px;
  border: 1px solid black;
  padding-left: 33px;
`

export const MaskedInput = styled(ReactInputMask)`
  height: 41px;
  width: 100%;
  align-items: center;
  border-radius: 4px;
  border: 1px solid black;
  padding-left: 33px;
`

export const EntryDay = styled.div`
  position: absolute;
  top: 51.5%;
  left: 10px; /* Espaçamento entre a borda e a imagem */
  transform: translateX(160%);
  width: 20px; /* Tamanho da imagem */
  height: 20px;
`
export const InputArea = styled.div`
  border-radius: 4px;
  border: 1px solid var(--text-primary);
  background: #FFFFFF;
  display: flex;
  height: 38px;
  align-items: center;
  padding-left: 5px;

  & input{
    background: transparent;
    border: none;
    height: 36px;
    width: 100%;
    align-items: center;
    border-radius: 4px;
    padding-left: 10px;

    &:focus{
      box-shadow: 0 0 0 0;
      outline: 0;
    }
  }
`

export const InputAreaIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  & svg{
    color:#000;
  }
`

export const EntryTextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid black;
  border-radius: 0.25rem;
  font-size: 1rem;
  height: 120px;
`
export const EntryCancel = styled.button`
  display: flex;
  width: 150px;
  height: 38px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;

  border: 2px solid var(--pix-transfer-modal-cancel-button-border);
  background: var(--pix-transfer-modal-cancel-button-background);
  color: var(--pix-transfer-modal-cancel-button-color);
`
export const EntryEmit = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: center;
  color: var(--text-primary);
  width: 100%;
  margin-bottom: 2%;

  & button {
    width: 410px;
    border-radius: 13px;
    height: 52px;
    border: 2px solid var(--pix-transfer-modal-cancel-button-border);
    background: var(--pix-transfer-modal-cancel-button-background);
    color: var(--pix-transfer-modal-cancel-button-color);
    font-weight: 900;
    font-family: Poppins;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

`

export const IconContainer = styled.div`
  width: 56px;
  height: 56px;
  flex-shrink: 0;
  background-color: var(--recent-icons-background);
  color: var(--recent-icons-color);
  border: var(--recent-icons-border);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;

  & a {
    width: 100px;
    color: var(--text-primary);
    text-align: center;
    font-family: Poppins;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 14px !important;
    letter-spacing: 1px !important;
    text-transform: uppercase !important;
    cursor: pointer;
  }

`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 10%;
  margin-right: 40px;
`

export const EntryCards = styled.div`
  display: flex;
`

export const Input = styled.input`
  height: 39px;
  width: 100%;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #aca5a5;
  padding-left: 33px;
  margin-bottom: 20px;
`

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: start;
  color: var(--text-primary);
  width: 100%;
  margin-top: -8%;
  margin-bottom: 2%;

  & h2 {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  & label {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-right: 10px;
  }

  & section {
    display: flex-column;
    width: 100%;
    margin: 0;
  }

  & img {
    margin-left: 18%;
  }
`
export const EntrySearch = styled.div`
  position: absolute;
  top: 57.7%;
  left: 10px; /* Espaçamento entre a borda e a imagem */
  transform: translateX(160%);
  width: 20px; /* Tamanho da imagem */
  height: 20px;
`

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-primary);
  width: 400px;
  margin-bottom: 5%;
  background-color: #D3D3D3;
  height: 230px;
  margin-left: 110px;
  border-radius: 10px;

  & h2 {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  & p {
    margin-top: 7px;
    color: black !important;
  }
  & label {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-right: 10px;
  }

  & section {
    display: flex-column;
    width: 100%;
    margin: 0;
  }

  & img {
    margin-top: 20px;
  }
`

export const EntryImages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3%;
  border: none;

  & label {
    margin-bottom: 0.5rem;
  }
  &input {
    width:78%;
    padding: 0.5rem;
    border: 3px solid black;
    border-radius: 0.25rem;
    font-size: 1rem;
  }
  &textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    font-size: 1rem;
  }

  & strong {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0.5px;
  }

`
export const FormsItens = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  border: none;

  & label {
    margin-bottom: 0.5rem;
    font-family: Poppins;
    font-size: 13px;
    margin-top: 5px;
    font-weight: 500;
  }
  &input {
    width:78%;
    padding: 0.5rem;
    border: 3px solid black;
    border-radius: 0.25rem;
    font-size: 1rem;
  }
`

export const FormsButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: center;
  color: var(--text-primary);
  width: 100%;
  margin-bottom: 5%;
  margin-left: -70px;

 & button {
   margin-left: 130px;
   margin-right: 20px;
  }
`

export const ClientList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;

  & strong {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0.5px;
  }

  & p {
    font-weight: 550;
    font-size: 14px;
  }
`

export const NameList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  justify-content: center;
  width: 38%;
  border-bottom: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  background-color: #F7F7F7;;
  cursor: pointer;
  color: var(--text-primary);

  & strong {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0.5px;
  }

  & p {
    font-weight: 550;
    font-size: 14px;
  }
  & a {
    color: var(--text-primary);
  }
`
export const ErrorMessage = styled.p`
  position: absolute;
  color: #df0707;
  font-family: Poppins !important;
  font-size: 12px !important;
  font-weight: 500;
  margin-top: -3%;
`


export const BankDataEntrys = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: start;
  color: var(--text-primary);
  width: 100%;
  margin-bottom: -50px;


  & section {
    display: flex-column;
    width: 100%;
    border-radius: 50%;
    margin-left: 10px;
  }

  & img {
    margin-left: 1%;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    display: block;
  }
`

export const NameAvatar = styled.div`
  background: var(--primary-color);
  border-radius: 50%;
  padding: 15px;
  color: var(--change-password-modal-button-color);
`

export const Message = styled.p`
 margin-top: -1%;
 margin-bottom: 7%;
`

export const FilterItemMfa = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3%;
  margin-top: 4%;
  border: none;

& label {
  margin-bottom: 0.5rem;
  margin-left: 20%;
}
&textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  font-size: 1rem;
}

& strong {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0.5px;
}

& p {
  margin-top: 12px !important;
  font-weight: 550;
  font-size: 14px;
  margin-left: 15% !important;
}


& button {
  color: var(--primary-color);
}
`
export const Label = styled.label`
margin-left: 33% !important;
margin-bottom: 4px;
`
export const Preview = styled.p`
color: black !important;
`
export const EntryScrollValid  = styled.div`
  border-bottom: 1px solid grey;
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: start;
  color: var(--text-primary);
  width: 100%;
  margin-bottom: 2%;
  padding-bottom: 2%;

  & h2 {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  & label {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-right: 10px;
  }

  & section {
    display: flex-column;
    width: 100%;
    margin: 0;
  }

  & p {
    margin-bottom: 13px;
    margin-left: 10px;

  }

  & input {
    margin-bottom: 17px;
  }

`

export const BalanceEntry  = styled.p`
  color: var(--text-primary);
  margin-left: 4px;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  margin-top: -4px;
`
export const CancelEntry  = styled.strong`
  margin-top: 4px;
`

const hexToRgba = (hex, alpha = 1) => {
  const hexValue = hex.replace('#', '');
  const r = parseInt(hexValue.substring(0, 2), 16);
  const g = parseInt(hexValue.substring(2, 4), 16);
  const b = parseInt(hexValue.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const ClientCell = styled.div`
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  color: black !important;
  border-radius: 12px;
  background-color: ${({ selected, theme }) => selected ? hexToRgba(theme.colors['primary-color'], 0.2) : 'transparent'};
`

export const ActionButton = styled.button`
  font-size: 36px;
`

export const ClientCellData = styled.img`
  margin-right: 20px !important;
`
export const ClientRow = styled.div`
  height: 420px;
  overflow-y: auto;
`
export const ClientName = styled.p`
  font-size: 16px !important;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  margin-left: 10px;
`
export const ClienTaxNumber = styled.p`
  font-weight: 400 !important;
  font-size: 14px !important;
  margin-bottom: 0;
`

export const FilterButtonUpdate = styled.button`
  display: flex;
  width: 210px;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  flex-direction: row !important;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 11px;
  margin-left: 100px;

  background-color: ${({theme}) => theme.colors['filter-button-background'] };
  color: ${({theme}) => theme.colors['filter-button-color'] };
  font-weight: 900;
  font-family: Poppins;
  border: ${({theme}) => theme.colors['filter-button-border'] };
`

export const EntryCancelUpdate = styled.button`
  display: flex;
  flex-direction: row !important;
  width: 210px;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 11px;

  border: 2px solid var(--pix-transfer-modal-cancel-button-border);
  background: var(--pix-transfer-modal-cancel-button-background);
  color: var(--pix-transfer-modal-cancel-button-color);
`

export const FormsButtonsUpdate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: center;
  color: var(--text-primary);
  width: 100%;
  margin-bottom: 5%;
  margin-left: -10px;
  margin-top: 25px;

`

export const NotFoundMessage = styled.p`
margin-top: 5px !important;
margin-left: 210px !important;
`

export const EntryScroll_  = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: start;
  color: var(--text-primary);
  width: 100%;

  & h2 {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  & label {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-right: 10px;
  }

  & section {
    display: flex-column;
    width: 100%;
    margin: 0;
  }

  & p {
    margin-bottom: 13px;
    margin-left: 10px;

  }

  & input {
    margin-bottom: 17px;
   
  }


`
export const EntryValue_  = styled.p`
  margin-left: 155px !important;
  margin-right: 0px !important;
`

export const EntryRadio_  = styled.input`
  margin-right: 250px !important;
`