import { toast } from "react-toastify";
import { apiV1 } from "../lib/axios";

export const sendTedFitBank = async (data) => {
  try {
    const response = await apiV1.post("/ted/send", data);
    return response; 
  } catch (err) {
    toast.error(`${err.response.data.message} - TD005`);
  }
  return { data }; 
};

export const sendMfaTedFitBank = async () => {
await apiV1.post("/ted/send/mfa")
    .catch((err) => {
      toast.error("Erro ao enviar o MFA",err);
      return;
    });
  return
}

export const sendMfaAuthenticate = async (otp_) => {
  const otp = {otp:otp_}
  const response = await apiV1.post("/ted/send/mfa/authenticate",otp)
      .catch((err) => {
        toast.error("Erro ao autenticar o MFA",err);
        return;
      });
  
    return response;
  }